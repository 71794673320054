@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  height: 46px;
  width: 46px;
  position: relative;
  &-header {
    position: absolute;
    z-index: 500;
  }
  &-profile_info {
    width: 97px;
    height: 45px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: end;

    @include min-1440-break {
      width: 126px;
    }
    &-name {
      text-align: right;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--cst-drop-listprofile-text);

      @include typography-s;
      @include weight-semi-bold;

      @include min-1440-break {
        @include typography-m;
      }
    }
    &-balance {
      color: var(--cst-drop-listprofile-amount);

      @include typography-s;
      @include weight-semi-bold;

      @include min-1440-break {
        @include typography-base;
      }
    }
  }
}
.drop_list {
  min-width: 173px;
  right: -8px;
  top: -8px;
  background: var(--cst-drop-listprofile-background);
  box-shadow: var(--elevation-1);
  border-radius: 12px;
  border: none;
  padding: 8px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  @include min-1440-break {
    gap: 4px;
    min-width: 202px;
  }

  &-item {
    @include fixedSize(100%, 40px);
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 11px;

    @include min-1440-break {
      gap: 8px;
      @include fixedSize(100%, 46px);
    }

    p[id='list-item-title'] {
      color: var(--cst-dropitem-text-default);
      @include typography-s;
      @include weight-medium;

      @include min-1440-break {
        @include typography-m;
      }
    }
  }
}

.item-icon {
  @include singleFixedSize(18px);

  @include min-1440-break {
    @include singleFixedSize(22px);
  }
}
